
/*--------------------------------------------------------------
# faq
--------------------------------------------------------------*/
.faq {
    padding: 60px 0px 0px 0px;
  }
  
  .faq .question-icon {
    position: absolute;
    top: 14px;
    left: 25px;
    font-size: 24px;
    color: #3c9e18;
  }
  .faq h3{
    font-size: 30px;
    font-family: "Roboto", sans-serif; 
    padding-left: 10px;
    color: #000;
    line-height: 110%;
    font-weight: 600;
  }
  .faq .accordion-button:after {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #3c9e18;
  }
  .faq .accordion-body p {
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    line-height: 130%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #000;
  }
  .faq .negocio-title-left h3,  .faq .negocio-title-left h3 span{
    text-align: left;
    padding-bottom: 30px;
    font-size: 48px;
    font-family: "Roboto", sans-serif;
}
  .faq .negocio-title-left span{
    background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0px black;
    filter: drop-shadow(1px 1px #555555);
  
  }
  h3.accordion-header {
    padding-left: 0px;
  }

  
  @media (max-width: 990px) {
    .negocio .negocio-title h3 {
        font-size: 50px;
    }
    .negocio .icon-box .icon i {
        color: #3c9e18;
        border-radius: 4px;
        padding: 20px 20px;
        font-size: 44px;
        transition: ease-in-out 0.3s;
    }
    .negocio .icon-box .icon {
        margin: 0 auto;
        width: 85px;
        height: 80px;
        padding: 20px 20px;
    }
    .negocio .icon-box h4  {
        padding-top: 30px;
        font-size: 30px;
    }
    .negocio .icon-box p {
        line-height: 120%;
        font-size:28px;
        font-family: "Roboto", sans-serif;
        text-align: left;
        color: #555555;
    }
    .negocio .icon-box {
        border: 1px solid #e1f5de;
        padding: 60px 20px 20px 20px;
        transition: all ease-in-out 0.3s;
        background: #fff;
        box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
        height: 820px;
    }

    .faq {
        padding: 60px 0;
        overflow: hidden;
    }
    .faq .question-icon {
        position: absolute;
        top: 14px;
        left: 25px;
        font-size: 30px;
        color: #3c9e18;
    }
    .faq h3{
        font-size: 30px;
        font-family: "Roboto", sans-serif; 
        padding-left: 10px;
    }
    .faq .accordion-button:after {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #3c9e18;
    }
    .faq .accordion-body p {
        font-size: 28px;
        font-family: "Roboto", sans-serif;
        line-height: 140%;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .faq .negocio-title-left h3{
        text-align: left;
        padding-bottom: 30px;
        font-size: 50px;
        font-family: "Roboto", sans-serif;

    }
    .faq .negocio-title-left span{
        background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 0px black;
        filter: drop-shadow(1px 1px #555555);
 

    }
    h3.accordion-header {
        padding-left: 0px;
    }
}
@media (max-width: 580px) {
    .negocio  .negocio-title h3 {
        font-size: 40px;
        text-align: center;
    }
    .negocio .icon-box {
        border: 1px solid #e1f5de;
        padding: 60px 20px 20px 20px;
        transition: all ease-in-out 0.3s;
        background: #fff;
        box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
        height: 550px;
    }
    .faq .negocio-title-left h3{
        text-align: left;
        padding-bottom: 30px;
        font-size: 40px;
        font-family: "Roboto", sans-serif;

    }
    .faq .accordion-body p {
        font-size: 24px;
        font-family: "Roboto", sans-serif;
        line-height: 110%;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .faq h3 {
        font-size: 28px;
        font-family: "Roboto", sans-serif;
        padding-left: 10px;
    }
    .faq .question-icon {
        position: absolute;
        top: 14px;
        left: 25px;
        font-size: 28px;
        color: #3c9e18;
    }
    h3.accordion-header {
        padding-left: 0px;
    }
}