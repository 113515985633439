/*--------------------------------------------------------------
# contacto
--------------------------------------------------------------*/

.contacto .info-box {
  color: #000;
  text-align: center;
  padding: 40px 20px 40px 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.contacto .info-box i {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  padding: 15px;
  color: #3c9e18;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f6f9fe;
  border-radius: 4px;
  border: 1px solid #f6f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}


.contacto .info-box h3 {
  font-size: 34px;
  color: #000;
  font-weight: 700;
  margin: 30px 0px 30px 0px;
  font-family: "Roboto", sans-serif;
}

.contacto .info-box p {
  padding: 0;
  line-height: 110%;
  font-size: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #555555;
  text-align: center;
}
.contacto .info-box a {
  font-size: 24px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  color: #555555;
}

.contacto .info-box a:hover {
  color: #0593ea;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.contacto .info-box:hover {
  transform: scale(1.08);
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-color: #fff;
}

.contacto .info-box:hover h3{
  border-color:#0593ea;
    color: #0593ea;
}

.contacto .info-box:hover  i{
  border-color:#0593ea;
    color: #0593ea;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.contacto .form{
  margin-top: 60px;
  margin-bottom: 80px;
}

.contacto .info-box .flag{
  width:30px;
  height:20px;
  margin-right: 10px;
  border-radius: 4px;
}

.img-call {
  max-width: 100%;
  height: 100%;
}

.btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
}

.btn-get-started:hover {
  background: #0593ea;
}



.section-title-contact h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.section-title-contact h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}
.section-title-contact p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}



/*----------mobile-----------------*/

@media (max-width: 990px) {
  .section-title h3 {
    font-size: 60px;
  }
  .section-title p {
    font-size: 28px;
  }
  .contacto .info-box h3 {
    font-size: 34px;
    color: #000;
    font-weight: 700;
    margin: 40px 0px 10px 0px;
    font-family: "Roboto", sans-serif;
  }
  .contacto .info-box p {
    padding: 15px;
    line-height: 150%;
    font-size: 30px;
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: #555555;
  }
  .contacto .info-box a {
    font-size: 30px;
    padding: 0px 20px 0px 20px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    color: #555555;
  }
  .img-call {
    padding-top: 60px;
    max-width: 100%;
    height: 100%;
  }
  .formulario input,
  .formulario select,
  .formulario textarea {
    font-size: 18px;
    background: #efefef;
  }

  .formulario input:focus {
    outline: none;
  }
}

@media (max-width: 580px) {
  .titulo_contacto {
    padding: 10px 0px 30px 0px;
  }
  .contacto {
    padding: 0px 20px 0px 20px;
  }
  .contacto .info-box p {
    padding: 10px;
  }
  .contacto .info-box h3 {
    padding: 10px;
    margin: 40px 0px 10px 0px;
  }
  .section-title h3 {
    font-size: 50px;
  }
  .section-title p {
    font-size: 24px;
  }
  .contacto .info-box a {
    font-size: 22px;
    padding: 20px 20px;
  }
  .contacto .info-box p {
    font-size: 22px;
    text-align: center;
  }
  .img-call {
    padding-top: 50px;
    max-width: 100%;
    height: 100%;
  }
}
