/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog Posts List
--------------------------------------------------------------*/
.blog .posts-list article {
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  padding: 30px;
  height: 100%;
}

.blog .posts-list article + article {
  margin-top: 60px;
}

.blog .posts-list .post-img {
  max-height: 350px;
  margin: -30px -30px 0 -30px;
  overflow: hidden;
}

.blog .posts-list .title {
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
}

.blog .posts-list .title a {
  color: #000000;
  transition: 0.3s;
  text-decoration: none;
  line-height: 150%;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
}

.blog .posts-list .title a:hover {
  color: #0593ea;
  text-decoration: none;
}

.blog .posts-list .meta-top {
  margin-top: 15px;
  color: #3c9e18;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.blog .posts-list .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .posts-list .meta-top ul li + li {
  padding-left: 20px;
}

.blog .posts-list .meta-top i {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-right: 8px;
  line-height: 0;
  color: #3c9e18;
}

.blog .posts-list .meta-top a {
  color: #000000;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.blog .posts-list .content {
  margin-top: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #555555;
  line-height: 150%;
}

.blog .posts-list .read-more a {
  display: inline-block;
  background: #3c9e18;
  color: #ffffff;
  padding: 8px 20px;
  transition: 0.3s;
  font-size: 18px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}

.blog .posts-list .read-more a:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}


/*--------------------------------------------------------------
  # Blog Sidebar
  --------------------------------------------------------------*/
.blog .sidebar {
  padding: 30px;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  font-family: "Roboto", sans-serif;
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: #000000;
  font-size: 24px;
}

.blog .sidebar .sidebar-item + .sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #0593ea;
  border: 1px solid #0593ea;
  padding: 3px 10px;
  position: relative;
  font-size: 16px;
  border-radius: 4px;
}

.blog .sidebar .search-form h3,
.blog .sidebar .categories h3,
.blog .sidebar .recent-posts h3,
.blog .sidebar .tags h3 {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form input[type="text"]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #0593ea;
  color: #ffffff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: rgba(var(--color-primary-rgb), 0.8);
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #555555;
  transition: 0.3s;
  text-decoration: none;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.blog .sidebar .categories ul a:hover {
  color: #0593ea;
  font-size: 20px;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #3c9e18;
  font-size: 20px;
  font-weight: bold;
}

.blog .sidebar .recent-posts .post-item {
  display: flex;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  margin-right: 15px;
}

.blog .sidebar .recent-posts h4 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.blog .sidebar .recent-posts h4 a {
  color: #000000;
  transition: 0.3s;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  font-weight: 600;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #0593ea;
}

.blog .sidebar .recent-posts time {
  display: block;
  font-style: italic;
  font-size: 16px;
  color: #3c9e18;
  font-family: "Roboto", sans-serif;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #0593ea;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #0593ea;
  display: inline-block;
  transition: 0.3s;
  text-decoration: none;
  border-radius: 4px;
}

.blog .sidebar .tags ul a:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  color: #ffffff;
  border: none;
}

.blog .sidebar .tags ul .selected {
  background: #3c9e18;
  color: #ffffff;
  padding: 6px 14px;
  border: none;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #000000;
  font-size: 16px;
}


/*--------------------------------------------------------------
# Recent Blog Posts
--------------------------------------------------------------*/
.recent-blog-posts .post-box {
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.recent-blog-posts .post-box .post-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.recent-blog-posts .post-box .post-img img {
  transition: 0.5s;
}

.recent-blog-posts .post-box .meta {
  margin-top: 15px;
}

.recent-blog-posts .post-box .meta .post-date {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-primary);
}

.recent-blog-posts .post-box .meta .post-author {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-secondary);
}

.recent-blog-posts .post-box .post-title {
  font-size: 24px;
  color: var(--color-secondary);
  font-weight: 700;
  margin: 15px 0 0 0;
  position: relative;
  transition: 0.3s;
}

.recent-blog-posts .post-box p {
  margin: 15px 0 0 0;
  color: rgba(var(--color-secondary-dark-rgb), 0.7);
}

.recent-blog-posts .post-box .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  margin-top: 15px;
}

.recent-blog-posts .post-box .readmore i {
  line-height: 0;
  margin-left: 4px;
  font-size: 18px;
}

.recent-blog-posts .post-box:hover .post-title {
  color: var(--color-primary);
}

.recent-blog-posts .post-box:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Blog suscribete
  --------------------------------------------------------------*/
.notice {
  background: url("../../assets/img_logos_radar/banner_trans.png");
  background-repeat: no-repeat;
  background-color: #f1f6fe;
  background-attachment: fixed;
  background-position: center;
}

.notice h1 {
  color: #0593ea;
  font-weight: 700;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
}

.notice h6 {
  color: #555555;
  font-weight: 700;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.notice .notices {
  padding: 30px 0;
  text-align: center;
  font-size: 15px;
}

.button_blog {
  color: #555555;
  font-weight: 300;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 0;
  background-color: #f1f6fe;
}
.btn-button-input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  color: #555555;
  border-color: #3c9e18;
}

.btn-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
}

.btn-button:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}


.page-link.active, .active > .page-link {
  background: #3c9e18;
  color: #ffffff;
  border: none;
  font-size: 24px;
}

.section-title-blog h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.section-title-blog h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}
.section-title-blog p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

@media (max-width: 990px) {
  .section-title h3 {
    font-size: 60px;
  }
  .section-title p {
    font-size: 28px;
  }
  .blog {
    padding: 0px 20px 0px 20px;
  }
  .blog .posts-list .title {
    font-weight: 700;
    padding: 0;
    margin: 20px 0 0 0;
    font-size: 34px;
    font-family: "Roboto", sans-serif;
  }

  .blog .posts-list .meta-top a {
    font-size: 24px;
  }
  .blog .posts-list .content {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 30px;
  }
  .blog .posts-list .read-more a {
    font-size: 28px;
  }

  .blog .sidebar .search-form h3,
  .blog .sidebar .categories h3,
  .blog .sidebar .recent-posts h3,
  .blog .sidebar .tags h3 {
    font-family: "Roboto", sans-serif;
    font-size: 34px;
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 20px;
  }



  .blog .sidebar .search-form form {
    font-size: 28px;
  }

  .blog .sidebar .categories ul a {
    font-size: 28px;
  }
  .blog .sidebar .categories ul a span {
    font-size: 24px;
  }
  .blog .sidebar .recent-posts h4 a {
    font-size: 24px;
  }
  .blog .sidebar .recent-posts time {
    display: block;
    font-style: italic;
    font-size: 24px;
    color: #3c9e18;
    font-family: "Roboto", sans-serif;
  }
  .blog .sidebar .tags ul a {
    font-size: 24px;
  }

  .button_blog {
    padding: 20px;
  }

  .button_blog input {
    font-size: 24px;
    padding: 0px;
  }
  .blog .posts-list .title a {
    font-size: 34px;
}
}

@media (max-width: 580px) {
  .titulo_blog {
    padding: 10px 0px 30px 0px;
}
  .blog {
    padding: 0px 30px 0px 30px;
  }
  .section-title h3 {
    font-size: 50px;
  }
  .section-title p {
    font-size: 24px;
  }
  .blog .posts-list .content p {
    margin-top: 0px;
    margin-bottom: 30px;
    font-size: 24px;
  }
  .blog .posts-list .meta-top a {
    font-size: 20px;
  }
  .blog .posts-list .read-more a {
    font-size: 18px;
  }
}
