/*--------------------------------------------------------------
# about info
--------------------------------------------------------------*/
.portfolio
{
  padding-bottom:60px ;
}

/* blue */
.about .icon-box {
  text-align: center;
  border: 1px solid #e1f5de;
  border-top: 5px solid #0593ea;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.about .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  color: #0593ea;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  border-top: 5px solid #0593ea;
  display: block;
}

/* green */
.about .icon-box-green {
  text-align: center;
  border: 1px solid #e1f5de;
  border-top: 5px solid #3c9e18;
  text-decoration: none;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.about .icon-box-green:hover {
  border-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  color: #3c9e18;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  border-top: 5px solid #3c9e18;
}

.about .icon-box-green {
  position: relative;
}

.about .icon-box-green .link {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.about .icon-box-green:hover .link {
  display: inline;
}


/* purple */

.about .icon-box-purple {
  text-align: center;
  border: 1px solid #e1f5de;
  border-top: 5px solid #9c17ff;
  padding: 20px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.about .icon-box-purple:hover {
  border-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  color: #9c17ff;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  border-top: 5px solid #9c17ff;
}

.about .icon-box h4 a,
.about .icon-box-green h4 a,
.about .icon-box-purple h4 a {
  color: #000000;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

.about .icon-box p,
.about .icon-box-green p,
.about .icon-box-purple p {
  line-height: 150%;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  text-align: center;
  color: #555555;
}

.about .icon-box h4,
.about .icon-box-green h4,
.about .icon-box-purple h4 {
  font-weight: 600;
  padding: 10px 20px;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
}

.about .icon-box:hover {
  transform: scale(1.08);
}
.about .icon-box-green:hover {
  transform: scale(1.08);
}
.about .icon-box-purple:hover {
  transform: scale(1.08);
}

.about .icon-box {
  position: relative;
}

.about .icon-box .link {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.about .icon-box:hover .link {
  display: inline;
}


@media (max-width: 990px) {
  .about .icon-box-green,
  .about .icon-box {
    height: 420px;
    width: 100%;
  }
  .about {
    padding: 20px 20px;
  }
  .about .icon-box p,
  .about .icon-box-green p,
  .about .icon-box-purple p {
    font-size: 28px;
    padding-top: 20px;
  }
  .about .icon-box h4,
  .about .icon-box-green h4,
  .about .icon-box-purple h4 {
    font-weight: 700;
    padding: 0px 20px;
    font-size: 34px;
    font-family: "Roboto", sans-serif;
  }
}

@media (max-width: 580px) {
  .about .icon-box-green,
  .about .icon-box {
    height: 350px;
    width: 100%;
  }

  .about .icon-box h4,
  .about .icon-box-green h4,
  .about .icon-box-purple h4 {
    font-size: 28px;
  }
  .about .icon-box p,
  .about .icon-box-green p,
  .about .icon-box-purple p {
    font-size: 24px;
  }
  .portfolio {
    padding: 0px 0;
    overflow: hidden;
  }
}


