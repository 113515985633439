/*--------------------------------------------------------------
# questions Section
--------------------------------------------------------------*/
.question_list {
  padding: 0px 0px 60px 0px;
  overflow: hidden;
}
@media (max-width: 771px) {
  .question_list {
    padding: 20px;
  }
  .questions-title {
    text-align: center !important;
  }
}

/*-----------------------------------------
  #boton acordion
  ------------------------------------------*/

.question_list .accordion-item {
  border: 0;
  margin-top: 15px;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-radius: 4px;
}

.question_list .accordion-collapse {
  border: 10px;
}

.question_list .accordion-button {
  padding: 15px 60px 20px 20px;
  font-weight: 400;
  border: 0;
  font-size: 24px;
  color: #000000;
  text-align: left;
  background: #ffffff;
  box-shadow: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
}

.question_list .accordion-button:not(.collapsed) {
  color: #000000;
  font-weight: 600;
  border-bottom: 0;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  background: #ffffff;
}

.question_list .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #3c9e18;
}

.question_list .accordion-body {
  padding: 0px 60px 10px 20px;
  border: 0;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: none;
  color: #555555;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  line-height: 130%;
  text-align: justify;
}

/*-----------------------------------------
  #boton search
  ------------------------------------------*/
.question_list .search {
  outline: none;
}
.question_list .search span {
  background: #0593ea;
}

.question_list .search span i {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.question_list .search-input {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.question_list .search-input::placeholder {
  color: #0593ea;
}

.question_list .search-input:focus::placeholder {
  color: #0593ea;
}

.question_list .search-input:focus {
  box-shadow: none;
}

/*-----------------------------------------
  #boton list stilo
  ------------------------------------------*/
.question_list .button_questions {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  border-radius: 4px;
  border-width: 0;
  border: 1px solid #d6d5d5;
  transition: 0.5s;
  color: #000000;
  background: none;
  text-decoration: none;
  padding: 5px 10px;
  text-transform: capitalize;
}

.question_list .button_questions:focus {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  color: #ffffff;
}

.question_list .button_questions:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  color: #ffffff;
}

/*-----------------------------------------
  #boton list 
  ------------------------------------------*/
.question_list .question-title-accordion {
  display: flex;
  justify-content: flex-end;
  padding: 50px 0px;
}

.question_list .question-title-accordion .box-accordion .active-button {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  color: #ffffff;
  text-transform: uppercase;
}

.question_list .question-title-accordion .box-accordion {
  margin-right: 10px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

/*------paginador----*/
.page-link {
  position: relative;
  display: block;
  color: #000000;
  font-size: 24px;
  text-decoration: none;
  background-color: #ffffff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-link:focus {
  box-shadow: none;
}

.page-link.active {
  background: #3c9e18;
  color: #ffffff;
  border: none;
  font-size: 24px;
}

.page-link:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  color: #ffffff;
}

/*--------mobile------*/

@media (max-width: 990px) {
  .question_list .question-title-accordion {
    flex-wrap: wrap;
    text-align: left;
    line-height: 120%;
  }

  .question_list .question-title-accordion .box-accordion {
    margin: 5px 0;
    border-bottom: none;
    border-radius: 0;
  }

  .question_list {
    padding: 0px 20px 60px 20px;
  }
  .question_list .search-input {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  .question_list .button_questions {
    font-size: 24px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 24px;
  }
  .question_list .accordion-button:not(.collapsed) {
    font-size: 24px;
  }
  .question_list .accordion-body {
    padding: 0px 10px 10px 20px;
    font-size: 24px;
  }
  .page-link {
    font-size: 24px;
  }
  .question_list .question-title-accordion {
    padding-bottom: 40px;
  }
  .question_list .question-title-accordion {
    padding-top: 40px;
  }
  .question_list .accordion-body {
    padding: 0px 40px 10px 20px;
    font-size: 24px;
  }
  .question_list .accordion-button:not(.collapsed) {
    padding: 30px 40px 30px 20px;
    font-size: 24px;
  }
}

@media (max-width: 580px) {
  .question_list .question-title-accordion {
    flex-wrap: wrap;
    text-align: left;
    line-height: 130%;
  }

  .question_list .question-title-accordion .box-accordion {
    margin: 5px 0;
    border-bottom: none;
    border-radius: 0;
  }

  .question_list {
    padding: 0px 20px 60px 20px;
  }
  .question_list .search-input {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  .question_list .button_questions {
    font-size: 24px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 24px;
  }
  .question_list .accordion-button:not(.collapsed) {
    font-size: 24px;
  }
  .question_list .accordion-body {
    padding: 0px 10px 10px 20px;

    font-size: 24px;
  }
  .page-link {
    font-size: 24px;
  }
  .question_list .question-title-accordion {
    padding-bottom: 40px;
  }
  .question_list .question-title-accordion {
    padding-top: 40px;
  }
  .question_list .accordion-body {
    padding: 0px 40px 10px 20px;
    font-size: 24px;
  }
  .question_list .accordion-button:not(.collapsed) {
    padding: 30px 40px 30px 20px;
    font-size: 24px;
  }
}
