/*--------------------------------------------------------------
  # Blog Details
  --------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  padding: 30px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
  height: auto;
}

.blog .blog-details .post-img img {
  width: 100%;
}

.blog .blog-details .title {
  padding: 0;
  margin: 40px 0 40px 0;
}
.blog .blog-details .title h2 {
  font-size: 34px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000000;
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #000000;
}

.blog .blog-details .content p {
  color: #000000;
  line-height: 130%;
  font-size: 24px;
}

.blog .blog-details .content img {
  width: 100%;
  margin-top: 20px;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: #f1f6fe;
  padding: 40px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: #000000;
  line-height: 130%;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #3c9e18;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: #3c9e18;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li + li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-right: 8px;
  line-height: 0;
  color: #3c9e18;
}

.blog .blog-details .meta-top a {
  color: #000000;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.blog .post-author {
  padding: 18px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
  border-radius: 50%;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px;
  padding: 0;
  color: #3c9e18;
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: #555555;
  margin-right: 5px;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

.blog .post-author p {
  font-style: italic;
  color: #000000;
  margin-bottom: 0;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}


.blog .table {
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  padding: 20px;
  height: auto;
}

.blog .table ul {
  list-style: none;
  padding: 0;
}

.blog .table ul li + li {
  padding-top: 10px;
}
.blog .table ul li  {
  padding-top: 10px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.blog .table ul a {
  color: #000000;
  transition: 0.3s;
  text-decoration: none;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 0;
}

.blog .table ul a:hover {
  color: #0593ea;
  font-size: 20px;
}

.blog .table ul a span {
  padding-left: 5px;
  color: #3c9e18;
  font-size: 20px;
  font-weight: bold;
}
.blog .table h3 {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 20px;
}

.blog .table .button p {
  display: inline-block;
  background: #3c9e18;
  margin-top: 50px;
  color: #ffffff;
  padding: 20px 20px;
  transition: 0.3s;
  font-size: 18;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}

.blog .table .button p:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}


.blog .blog-details .meta-bottom {
  padding-top: 10px;
  margin-top: 50px;
  border-top: 1px solid #3c9e18;
  text-transform:capitalize;
}

.blog .content .tags {
  margin-bottom: 10px;
}

.blog .content .tags ul {
  list-style: none;
  padding: 0;
}

.blog .content .tags ul li {
  display: inline-block;

 
}

.blog .content .tags ul li a{
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #0593ea;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #0593ea;
  display: inline-block;
  transition: 0.3s;
  text-decoration: none;
  border-radius: 4px;
}

.blog .content .tags ul li a.active {
  background: #3c9e18;
  color: #ffffff;
  padding: 6px 14px;
  border: none;
  border-radius: 4px;
}

.content p .highlighted {
  background-color: yellow;
  color: #000;
  border-radius: 4px;
  padding: 1px 5px;
}



.blog .table-button .button p {
  display: inline-block;
  background: #3c9e18;
  margin-top: 50px;
  color: #ffffff;
  padding: 20px 20px;
  transition: 0.3s;
  font-size: 18;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}

.blog .table-button .button p:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

@media (max-width: 990px) {
.blog .table ul a:hover,
.blog .table ul a,
.blog .post-author .social-links a,
.blog .blog-details .meta-bottom a,
.blog .blog-details .meta-top a   {
  font-size: 28px;
}

.blog .post-author p,
.blog .blog-details .content blockquote p,
.blog .blog-details .content p {
  font-size: 30px;
}

.blog .blog-details .content blockquote p{
  font-size: 28px;
}

.blog .table h3,
.blog .blog-details .content h3,
.blog .blog-details .title h2{
  font-size: 34px;
}

}


.highlighted  {
  background-color: yellow;
}