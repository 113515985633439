/*--------------------------------------------------------------
# banner Section
--------------------------------------------------------------*/

.slider-container {
  width: 100%;
  overflow: hidden;
  padding-top: 10px;

}
.slider-container .slider {
  display: flex;
  transition: transform 0.5s ease;

}

/*-------position img---------*/
.slider-container .slider .slide {
  flex: 0 0 100%;
  width: 100%;
  text-align: right;
  padding-right: 50px;
  transition: opacity 0.5s ease;
  flex-direction: column;
}
.slider-container .slider .slide.active {
  opacity: 1;
}
.slider-container .slider .slide img {
  max-width: 100%;
  height: 500px;
}

/*-------controls---------*/
.slider-container .controls {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.slider-container .control {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #106eea;
  margin: 0 5px;
  cursor: pointer;
}
.slider-container .control.active {
  background-color: #3c9e18;
}

/*-------content---------*/

.slider-container .slider .caption {
  position: absolute;
  top: 60%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  border-radius: 4px;
  width: 50%;
}

.slider-container .slider .caption .title {
  text-align: left;
  padding-bottom: 30px;
}

.slider-container .slider .caption .title h3 {
  font-size: 60px;
  color: #000000;
  padding: 0px 0 15px 0px;
  font-weight: 700;
  line-height: 110%;
  font-family: "Roboto", sans-serif;
}
.slider-container .slider .caption .title h3 span {
  font-size: 60px;
  color: #000000;
  padding: 0px 0 15px 0px;
  font-weight: 400;
  line-height: 110%;
  font-family: "Roboto", sans-serif;
}
.slider-container .slider .caption .title h3 strong {
  font-size: 60px;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  padding: 0px 0 15px 0px;
  font-weight: 700;
  line-height: 110%;
  font-family: "Roboto", sans-serif;
}
.slider-container .slider .caption .title p {
  color: #555555;
  font-weight: 300;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}


.slider-container .button_banner {
  color: #555555;
  font-weight: 300;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 0;
}
.slider-container .btn-button-input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  color: #555555;
  border-color: #3c9e18;
  align-content: center;
}

.slider-container .btn-button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
}
.slider-container .btn-button:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

@media (max-width: 990px) {
  .slider-container .slider .caption {
    position: relative;
    top: 35px;
    transform: translate(0px, 0px);
    width: 100%;
    left: 0;
    align-items: center;
  }

  .slider-container .slider .caption .title p {
    font-size: 28px;
    text-align: center !important;
  }
  .slider-container .button_banner {
    width: 80%;
  }
  .slider-container .button_banner input,
  .slider-container .button_banner button  {
    font-size: 18px;
  }

  .slider-container .slider .caption .title h3,
  .slider-container .slider .caption .title p {
    width: 100%;
    text-align: center !important;
    padding-left: 30px;
    padding-right: 30px;
  }

 
  .slider-container .slider .slide {
    padding-right: 0;
  }

  .slider-container .slider .slide img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .slider-container {
    width: 100%;
    padding-top: 30px;
  }
}

@media (max-width: 580px) {
  .slider-container .slider .slide img {
    width: 120%;
    height: 250px;
  } 
  .slider-container .slider .slide {
    padding-right: 0;
  }
  .slider-container {
    width: 100%;
    padding-top: 0px;
  }
  .slider-container .slider .caption .title h3,
  .slider-container .slider .caption .title p {
    width: 100%;
    text-align: center !important;
    padding-left: 5px;
    padding-right: 5px;
  }
  .slider-container .button_banner {
    width: 90%;
  }
  .slider-container .button_banner input,
  .slider-container .button_banner button  {
    font-size: 18px;
  }
 }