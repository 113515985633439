/*--------------------------------------------------------------
# tarifas
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.tarifas .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.tarifas .box:hover {
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-top: 5px solid #3c9e18;
  border-radius: 4px;
  transition: all ease-in-out 0.3s;
}
.tarifas .box h3:hover {
  color: #3c9e18;
}

.tarifas .box h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 30px;
  font-weight: 600;
  color: #000;
  background: #e2e1e1;
  font-family: "Roboto", sans-serif;
}

.tarifas .box .price h4 {
  font-size: 44px;
  color: #000000;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}

.tarifas .box .price h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.tarifas .box .price h4 span {
  color: #000;
  font-size: 25px;
  font-weight: 300;
}

.tarifas .box ul {
  padding: 0;
  list-style: none;
  color: #000;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.tarifas .box ul li {
  padding-bottom: 14px;
}

.tarifas .box ul i {
  color: #3c9e18;
  font-size: 18px;
  padding-right: 4px;
}

.tarifas .box ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.tarifas .box .caja {
  padding: 0px 0px 60px 0px;
  text-align: center;
}

.tarifas .box .caja p {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 110%;
  color: #555555;
}

.tarifas .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.tarifas .btn-buy {
  background: #3c9e18;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
  text-decoration: none;
}

.tarifas .btn-buy:hover {
  background: #0593ea;
}

.tarifas .featured h3 {
  color: #ffffff;
  background: #0593ea;
}

.tarifas .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #ffffff;
  color: #144906;
}

.tarifas .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #ffffff;
  color: #144906;
}

/*--------------------------------------------------------------
# section
--------------------------------------------------------------*/

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}
.section-title-pricing {
  text-align: center;
  padding-bottom: 30px;
}

.section-title-pricing h2 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e1f5de;
  color: #3c9e18;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title-pricing h3 {
  margin: 15px 0 0 0;
  font-size: 44px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.section-title-pricing h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}
.section-title-pricing h4 {
  margin: 15px 0 0 0;
  font-size: 34px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.section-title-pricing h4 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  font-size: 50px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  FONT-SIZE: 24px;
  font-family: "Roboto", sans-serif;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 60%;
  }
}

/*--------------------------------------------------------------
# Boton
--------------------------------------------------------------*/

.button-plan {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
  border:none;
}

.button-plan:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  text-decoration: none;
}

.bar-price {
  margin-top: 30px;
  margin-bottom: 30px;
}

.bar-price .input-plan {
  width: 100%;
  height: 15px;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.bar-price .input-plan:hover {
  opacity: 1;
}

.bar-price .input-plan::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #a4f3eb;
  cursor: pointer;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  background-size: 45%;
  background-position: center;
  background-repeat: no-repeat;
}
.bar-price p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}


/*--------------------------------------------------------------
# table
--------------------------------------------------------------*/
.table{
  margin-bottom: 0px;
}

.table  .table-title th {
  background: #e2e1e1;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: #000000;
  border-left: 5px solid #3c9e18;
}

.table  .table-info tr {
  border-bottom: #3c9e18;
}

.table  .table-info td {
  background: none;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #000000;
  padding-left: 20px;
  line-height: 110%;
  
}


/*--------------------------------------------------------------
# table-open-modal
--------------------------------------------------------------*/
.open-modal{
  margin-top: 20px;
  margin-bottom: 20px;
}
.open-modal p{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  padding: 10px 20px;
}

.open-modal p:hover{
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  border-radius: 4px;
}


/*----------mobile-----------------*/

@media (max-width: 990px) {
  .section-title h3 {
    font-size: 60px;
  }
  .servicios-title p {
    font-size: 28px;
  }
  .tarifas .box {
    margin-bottom: 50px;
  }
  .tarifas .box h3 {
    box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
    border-top: 5px solid #3c9e18;
    color: #000000;
    font-size: 34px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
  }

  .tarifas .box .caja p {
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    line-height: 150%;
  }

  .bar-price {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .bar-price .input-plan {
    width: 70%;
  }

  .bar-price p {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #555555;
  }

  .tarifas .box .price h4 {
    font-size: 48px;
    color: #000000;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
  }

  .tarifas .box .price h4 sup {
    font-size: 40px;
    top: -10px;
    left: -3px;
  }
  .tarifas .box .price h4 span {
    color: #000;
    font-size: 28px;
    font-weight: 300;
  }
  .button-plan {
    font-size: 24px;
  }
}

@media (max-width: 580px) {
  .titulo_planes {
    padding: 10px 0px 30px 0px;
}
  .tarifas {
    margin-bottom: 50px;
    padding: 0px 20px 0px 20px;
  }
  .servicios {
    padding: 0px;
  }
  .section-title h3 {
    font-size: 50px;
  }
  .servicios-title p {
    font-size: 24px;
  }
  .button-plan {
    font-size: 18px;
  }
}
