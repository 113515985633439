/*--------------------------------------------------------------
# marketing
--------------------------------------------------------------*/

.marketing .icon-box {
  text-align: center;
  border: 1px solid #e1f5de;
  padding: 30px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.marketing .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f6f9fe;
  border-radius: 4px;
  border: 1px solid #f6f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.marketing .icon-box .icon i {
  color: #3c9e18;
  font-size: 40px;
  transition: ease-in-out 0.3s;
}

.marketing .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}
.marketing .icon-box h4 button {
  font-weight: 700;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
  border: none;
  background: none;
}

.marketing .icon-box h4 a {
  color: #000000;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

.marketing .icon-box p {
  line-height: 130%;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  text-align: center;
  color: #555555;
}

.marketing .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}

.marketing .icon-box:hover h4 button,
.marketing .icon-box:hover h4 a,
.marketing .icon-box:hover .icon i {
  color: #0593ea;
  text-decoration: none;
}

.marketing .icon-box:hover .icon {
  border-color: #0593ea;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  padding: 0;
  margin-bottom: 60px;
  background: #e1f5de;
}

.cta .container {
  padding: 80px;
  border-radius: 15px;
}

@media (max-width: 992px) {
  .cta .container {
    padding: 60px;
  }
}

.cta .content h3 {
  color: #000;
  font-size: 48px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.cta .content p {
  color: #555555;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.cta .content .cta-btn {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.cta .content .cta-btn:hover {
  background: var(--color-primary);
}

.cta .img {
  position: relative;
}

.cta .img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #3c9e18;
  border-radius: 15px;
  transform: rotate(12deg);
}

.cta .img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: #0593ea;
  border-radius: 15px;
  transform: rotate(6deg);
}

.cta .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}
.cta .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
}

.cta .btn-get-started:hover {
  background: #0593ea;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo negro con opacidad */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  padding: 20px;
  width: 100%;
}
.modal-content {
  padding: 20px;
}
.modal-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #0593ea;
  margin-bottom: 10px;
  font-style: italic;
}

.modal-body {
  color: #555;
  font-size: 16px;
}

.modal-body p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #555555;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #0593ea;
  cursor: pointer;
}

.modal-open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.modal-header .btn-close {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  background: #3c9e18;
  padding: 10px 10px 25px 10px;
}


.btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
}

.btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.section-title-marketing h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.section-title-marketing h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}
.section-title-marketing p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}


@media (max-width: 990px) {
  .section-title h3 {
    font-size: 60px;
  }
  .section-title p {
    font-size: 28px;
  }
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  .marketing .icon-box {
    box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
    padding: 40px 20px;
  }
  .marketing {
    padding: 20px 20px;
  }

  .marketing .icon-box .icon {
    margin: 0 auto;
    width: 85px;
    height: 80px;
    padding: 20px 20px;
  }
  .marketing .icon-box .icon i {
    color: #3c9e18;
    font-size: 44px;
    transition: ease-in-out 0.3s;
    padding: 5px 5px 15px 5px;
  }
  .marketing .icon-box h4 button {
    font-size: 34px;
    padding-top: 40px;
  }
  .marketing .icon-box p {
    line-height: 120%;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0px;
    margin-top: 40px;
    text-align: center;
    color: #555555;
  }
  .modal-body p {
    font-size: 28px;
    color: #555555;
  }
  .modal-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #0593ea;
    margin-bottom: 10px;
    font-style: italic;
  }
}
@media (max-width: 580px) {
  .section-title h3 {
    font-size: 50px;
  }
  .section-title p {
    font-size: 24px;
  }
  section {
    padding: 80px 0;
    overflow: hidden;
  }
  #servicios-letter .btn-get-started {
    font-size: 18px;
  }
  .modal-body p {
    font-size: 24px;
    color: #555555;
  }
  .modal-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #0593ea;
    margin-bottom: 10px;
    font-style: italic;
  }
}
