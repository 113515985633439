/*--------------------------------------------------------------
# fidelity Counter Section
--------------------------------------------------------------*/
.fidelity-counter {
  padding: 60px 0;
}

.fidelity-counter .fidelity-item {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
}

.fidelity-counter .fidelity-item p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
}

.fidelity-counter .fidelity-item h3 {
  font-size: 50px;
  display: block;
  font-weight: 700;
  color: #000;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  padding-right: 20px;
}

@media (max-width: 990px) {
  .fidelity-counter .fidelity-item p {
    font-size: 24px;
  }
  .fidelity-counter .fidelity-item h3 {
    font-size: 44px;
  }
}

@media (max-width: 580px) {
  .fidelity-counter .fidelity-item {
    padding: 20px 20px;
    border-bottom: 1px solid #e4e4e4;
  }
}
