/*body[theme="dark"] {
  --background-body: black;
  --color-text: #fff;
  --background:  #555555;
  --  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);

}

body[theme="light"] {
  --background-body: rgb(255, 255, 255);
  --color-text: #000;
}

body {
  transition: 0.5s ease;
  background: var(--background-body) !important;
}

p,
span,
h6 h5,
h4,
h3,
h2,
h1,
li, .copyright,
a {
  color: var(--color-text) !important;
}

.footer-top,
ul,  #footer 
{
  background: var(--background) !important;
}
*/

/*---------------------------
----footer--------------------
----------------------------*/

#footer {
  background: #fff;
  color: #555555;
  background: #f1f6fe;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}

#footer .footer-top {
  padding: 30px 0 30px 0;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}


#footer .footer-top .footer-contact p {
  margin-bottom: 0;
  margin-right: 5px;
  line-height: 150%;
  color: #555555;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top .footer-contact a {
  color: #555555;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

#footer .footer-top .footer-contact a:hover {
  text-decoration: none;
  color: #0593ea;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top h2 {
  line-height: 40px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #000;
  position: relative;
}


#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0px 0px 0px 10px;
  margin: 0;
  color: #555555;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top .footer-links ul li {
  padding: 0;
  display: flex;
  align-items: center;
  color: #555555;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 5px;
}

#footer .footer-top .footer-links ul a p {
  color: #555555;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

#footer .footer-top .footer-links ul a i {
  color: #3c9e18;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  line-height: 1;
}

#footer .footer-top .footer-links p {
  color: #555555;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  text-decoration: none;
}


#footer .footer-top .footer-links ul a p:hover {
  text-decoration: none;
  color: #0593ea;
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

#footer .footer-top .footer-links .flag{
  width:30px;
  height:25px;
  margin-right: 10px;
  border-radius: 4px;
}

#footer .footer-top .social-links {
  padding-top: 5px;
}

#footer .footer-top .social-links a {
  font-size: 34px;
  display: inline-block;
  background: #3c9e18;
  color: #fff;
  line-height: 1;
  padding: 5px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 54px;
  height: 54px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}




#footer .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Open Sans", sans-serif;
}

#footer .logo a {
  color: #222222;
}

#footer .logo a span {
  color: #3c9e18;
}

#footer .logo img {
  max-height: 140px;
}

#footer .copyright {
  text-align: center;
  float: center;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #000;
}
.hide {
  display: block;
}
.hiden {
  display: none;
}



/*-----mobile----*/

@media (max-width: 990px) {
  #footer {
    float: none;
    padding: 2px 0;
  }
  #footer .footer-top {
    padding: 30px 20px 30px 20px;
  }

  #footer .footer-top .footer-contact p {
    margin-bottom: 20px;
    font-size: 28px;
  }
  #footer .footer-top .footer-contact a {
    font-size: 28px;
  }

  #footer .footer-top h2 {
    font-size: 28px;
    padding-top: 20px;
  }
  #footer .footer-top .footer-links ul a p {
    font-size: 28px;
  }
  #footer .footer-top .footer-links p {
    font-size: 28px;
  }
  #footer .copyright {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #footer .footer-top .social-links a {
    font-size: 44px;
    width: 70px;
    height: 70px;
    justify-content: center;
  }
  #footer .logo img {
    max-height: 150px;
  }

  .hide {
    display: none;
  }
  .hiden {
    display: block;
  }
  .hiden .footer-contact a:hover {
    text-decoration: none;
    color: #0593ea;
    font-weight: bold;
    font-size: 28px;
    font-family: "Roboto", sans-serif;
  }
  #footer .footer-top .footer-links ul a p:hover {
    text-decoration: none;
    color: #0593ea;
    font-weight: bold;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }
}

@media (max-width: 580px) {
  #footer .footer-top .social-links a {
    font-size: 40px;
    width: 60px;
    height: 60px;
    justify-content: center;
  }
  
  #footer .footer-top .footer-contact p {
    margin-bottom: 20px;
    font-size: 24px;
  }
  #footer .footer-top .footer-contact a {
    font-size: 24px;
  }

  #footer .footer-top h2 {
    font-size: 28px;
    padding-top: 20px;
  }
  #footer .footer-top .footer-links ul a p {
    font-size: 24px;
  }
  #footer .footer-top .footer-links p {
    font-size: 24px;
  }
  #footer .copyright {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #footer .logo img {
    max-height: 180px;
  }
}
