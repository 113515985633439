
  /*--------------------------------------------------------------
# Boton
--------------------------------------------------------------*/

.button-modal {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #3C9E18;
    text-decoration:none;
    align-content: center;
    border: none;
  }
  
  .button-modal:hover {
    background: linear-gradient(to right, #9c17ff, #0593ea ,#3C9E18);
    -webkit-text-stroke: 0px black;
    filter: drop-shadow(1px 1px #555555);
    text-decoration:none;
   
  }
  /*--------------------------------------------------------------
# encabezado
--------------------------------------------------------------*/

 .modal-content .modal-header{
    padding: 0px 0px;
    border-bottom:4px double #3C9E18;
  }

.modal-header .modal-title h4 {
  margin: 15px 0 0 0;
  font-size: 34px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #000;
}

.modal-header .modal-title h4 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  font-size: 38px;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
}

.modal-header .modal-title h3 {
  margin: 10px 0 0 0;
  font-size: 28px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #555555;
}

/*--------------------------------------------------------------
# table
--------------------------------------------------------------*/

.modal-table  .modal-table-title th {
  background: #e2e1e1;
  font-style: italic;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #000000;
  border-left: 5px solid #3c9e18;
  padding: 10px;
}


.modal-table  .modal-table-info tr {
  border-bottom:1px solid #3c9e18;
}

.modal-table .modal-table-info td {
  background: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #000000;
  padding-left: 20px;
  padding: 10px ;
  background-color: #f6f9fe;
}


