/*--------------------------------------------------------------
# questions Section
--------------------------------------------------------------*/
.questions {
  padding: 60px 0px;
  overflow: hidden;
}

/*-------------------------
#columna a
-------------------------*/

/*-#titulos, parrafos-----*/
.questions .questions-title {
  text-align: left;
  padding-bottom: 30px;
}

.questions .questions-title h3 {
  font-size: 48px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.questions .questions-title h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.questions .questions-title p {
  font-size: 24px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .questions .questions-title p {
    width: 70%;
    line-height: 130%;
  }
}

/*-#boton-----*/
.questions .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
  border: none;
}

.questions .btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

/*-------------------------
#columna b
-------------------------*/

/*--botones box-accordion-----*/

.questions .title-accordion {
  display: flex;
  justify-content: flex-end;
}

.questions .title-accordion .box-accordion .active-button {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  color: #ffffff;
  font-weight: 800;
}

.questions .title-accordion .box-accordion {
  margin-right: 10px;
}

.questions .button_questions {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: inline-block;
  border-radius: 4px;
  border-width: 0;
  border: 1px solid #d6d5d5;
  transition: 0.5s;
  color: #555555;
  background: none;
  text-decoration: none;
  padding: 5px 5px;
  text-transform: capitalize;
  line-height: 120%;
}

.questions .button_questions:focus {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  color: #ffffff;
}

.questions .button_questions:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  color: #ffffff;
}

/*--acordion-----*/


.questions .accordion {
  margin-top: 25px;
}
.questions .accordion-item {
  border: 0;
  margin-top: 15px;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  border-radius: 4px;
}

.questions .accordion-collapse {
  border: 10px;
}

.questions .accordion-button {
  padding: 15px 40px 20px 20px;
  font-weight: 400;
  border: 0;
  font-size: 20px;
  color: #000000;
  text-align: left;
  background: #ffffff;
  box-shadow: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
}

.questions .accordion-button:not(.collapsed) {
  color: #000000;
  font-weight: 600;
  border-bottom: 0;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  background: #ffffff;
}

.questions .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #3c9e18;
}

.questions .accordion-body {
  padding: 0px 10px 10px 20px;
  border: 0;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: none;
  color: #555555;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 130%;
}

.questions .accordion-item .questions .accordion-button,
.questions .accordion-item .questions .accordion-button.collapsed {
  border-radius: 4px;
}

@media (max-width: 990px) {
  .questions {
    padding: 20px;
  }
  .questions-title {
    text-align: center !important;
  }

  .questions .btn-get-started {
    font-size: 18px;
  }

  .questions .button_questions {
    font-size: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .questions .title-accordion {
    justify-content: left;
    flex-wrap: wrap;
    text-align: left;
  }

  .questions .title-accordion .box-accordion {
    margin: 5px 0;
    border-bottom: none;
    border-radius: 0;
  }

  .questions .questions-title {
    padding-bottom: 0px !important;
  }
  .questions {
    padding: 0px 20px 60px 20px;
  }
  .questions .accordion-button:not(.collapsed) {
    font-size: 24px;
  }
  .questions .accordion-body {
    font-size: 24px;
  }

  .questions .accordion-button {
    padding: 15px 40px 20px 20px;
    font-size: 24px;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    font-size: 24px;
  }

  .questions .questions-title p {
    font-size: 24px;
    color: #555555;
    font-family: "Roboto", sans-serif;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .questions .questions-title h3 {
    font-size: 50px;
    text-align: center;
  }
}

@media (max-width: 580px) {
  .negocio-title h3 {
    margin: 15px 0 0 0;
    font-size: 50px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }
  .questions .questions-title p {
    font-size: 28px;
    color: #555555;
    font-family: "Roboto", sans-serif;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 110%;
  }
  .questions .button_questions {
    font-size: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: left;
  }
  .questions .accordion-button {
    padding: 15px 40px 20px 20px;
    font-size: 24px;
  }
  .questions .accordion-body {
    font-size: 24px;
}
.questions .accordion-button:not(.collapsed) {
  font-size: 24px;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  font-size: 24px;
}
}
