.formulario {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 20px 0px 20px;
}

.formulario h1 {
  margin-bottom: 20px;
  font-size: 34px;
  color: #000;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.formulario form {
  display: flex;
  flex-direction: column;
}

.formulario .mb-3 {
  margin-bottom: 20px;
}

.formulario label {
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-style: italic;
}

.formulario input,
.formulario select,
.formulario textarea {
  width: 100%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #555555;
}
.formulario input:focus {
  outline: none;
}

/*--------------------------------------------------------------
# Boton
--------------------------------------------------------------*/

.button-form {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
  border-color: none;
  border: none;
}

.button-form:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

/*----------mobile-----------------*/

@media (max-width: 990px) {
  .formulario {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 40px 0px 40px;
  }
  .formulario h1 {
    margin-bottom: 30px;
    font-size: 38px;
  }
  .formulario label {
    font-weight: normal;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    font-style: italic;
  }

  .formulario input,
  .formulario select,
  .formulario textarea {
    font-size: 24px;
  }

  .button-form {
    margin-top: 20px;
    font-size: 24px;
  }
}
@media (max-width: 580px) {
  .button-form {
    margin-top: 20px;
    font-size: 18px;
  }
}
