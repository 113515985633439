/*--------------------------------------------------------------
# servicios
--------------------------------------------------------------*/
.servicios_list {
  padding: 0px 0px 60px 0px;
}

.servicios_list .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}

.servicios_list .member .member-img {
  position: relative;
  overflow: hidden;
}

.servicios_list .member .member-info {
  padding: 25px 15px;
  text-align: center;
}

.servicios_list .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
  color: #000;
}

.servicios_list .member .member-info span {
  display: block;
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 20px;
  font-weight: 400;
  color: #555555;
  font-family: "Roboto", sans-serif;
}

.servicios_list .member .social {
  text-align: right;
  padding: 0px 10px 0px 0px;
}

.servicios_list .member .social .links {
  transition: color 0.3s;
  margin: 0px 0px 10px 0px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: #3c9e18;
  font-size: 16px;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  text-transform: capitalize;
}

.servicios_list .member .social .links:hover {
  transition: color 0.3s;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

/*--------------------------------------------------------------
# section
--------------------------------------------------------------*/

section {
  padding: 60px 0;
  overflow: hidden;
}

.servicios-bg {
  background-color: #f6f9fe;
}
.servicios-title {
  text-align: center;
  padding-bottom: 30px;
}

.servicios-title h2 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e1f5de;
  color: #3c9e18;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.servicios-title h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.servicios-title h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.servicios-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  FONT-SIZE: 24px;
  font-family: "Roboto", sans-serif;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#servicios-letter {
  background: url("../../assets/img_logos_radar/banner_trans.png");
  background-repeat: no-repeat;
  background-color: #f1f6fe;
  background-attachment: fixed;
  background-position: center;
}

#servicios-letter h1 {
  color: #0593ea;
  font-weight: 700;
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

#servicios-letter h6 {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  padding-top: 20px;
  padding-bottom: 10px;
  line-height: 110%;
}

#servicios-letter .servicios-letters {
  padding: 30px 0;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

#servicios-letter .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
}

#servicios-letter .btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.cta {
  padding: 0;
  margin-bottom: 60px;
}

@media (max-width: 990px) {
  .servicios-title p {
    font-size: 28px;
  }
  .servicios-title h3 {
    font-size: 60px;
  }
  .servicios_list .member .member-info h4 {
    font-size: 34px;
  }
  .servicios_list .member .member-info span {
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 30px;
  }
  .servicios_list .member .social .links {
    transition: color 0.3s;
    margin: 0px 0px 10px 0px;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    display: inline-block;
    color: #ffffff;
    font-size: 24px;
    background: #3c9e18;
    padding: 10px 20px;
    text-decoration: none;
  }
  .servicios_list .member .social {
    text-align: right;
    padding: 0px 20px 20px 0px;
  }
  .servicios_list .member {
    margin-bottom: 40px;
    background: #fff;
    box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  }

  #servicios-letter h6 {
    font-size: 28px;
    padding-bottom: 30px;
    line-height: 150%;
  }
  #servicios-letter .btn-get-started {
    font-size: 24px;
  }
}

@media (max-width: 580px) {
  .titulo_servicios {
    padding: 10px 0px 30px 0px;
}
  .servicios_list {
    padding: 0px 20px 0px 20px;
  }

  .servicios_list .member {
    box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
    margin-bottom: 40px;
  }

  .servicios-title p {
    font-size: 24px;
  }
  .servicios-title h3 {
    font-size: 50px;
  }
  .servicios_list .member .social .links {
    font-size: 18px;
  }
  #servicios-letter h6 {
    font-size: 24px;
  }
  #servicios-letter .btn-get-started {
    font-size: 18px;
  }
  .servicios_list .member .member-info h4 {
    font-size: 30px;
  }
  .servicios_list .member .member-info span {
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 28px;
  }
}
