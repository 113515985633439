/*body[theme="dark"] {
  --background-body: black;
  --color-text: #fff;
  --background:  #555555;
  --  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);

}

body[theme="light"] {
  --background-body: rgb(255, 255, 255);
  --color-text: #000;
}

body {
  transition: 0.5s ease;
  background: var(--background-body) !important;
}

p,
span,
h6 h5,
h4,
h3,
h2,
h1,
li, .copyright,
a {
  color: var(--color-text) !important;
}

.accordion-button,
.accordion-body
{
  background: var(--background) !important;
}

.icon-box-green,
.icon-box
{
  background: var(--background) !important;
  box-shadow: var(--box-shadow) !important;
}

.button
{
  background: var(--background-body) !important;
}
*/

/*--------------------------------------------------------------
# negocio
--------------------------------------------------------------*/

.negocio .content h3 {
  font-weight: 600;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  line-height: 130%;
}

.negocio .content ul p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
}

.negocio .content ul {
  list-style: none;
  padding: 0;
}

.negocio .content ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.negocio .content ul li:first-child {
  margin-top: 35px;
}

.negocio .content ul i {
  margin-right: 15px;
  color: #3c9e18;
  border-radius: 50px;
  font-size: 24px;
  padding: 10px 20px 20px 20px;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
  background: #fff;
  align-items: center;
justify-content: center;
text-align: center;
}

.negocio .content ul h5 {
  font-size: 24px;
  color: #000000;
  font-family: "Roboto", sans-serif;
  padding-top: 5px;

}


.negocio .content p {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 120%;
   color: #555555;
}
.negocio .content p:last-child {
  margin-bottom: 0;
      margin-bottom: 0;
    color: #555555;
    line-height: 130%;
    font-size: 20px;
}

.negocio .img-about {
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(101, 102, 102, 0.3);

}

/*.negocio {
    padding: 60px 0;
    overflow: hidden;
  }*/

.negocio-bg {
  background-color: #d8e4f8;
}

.negocio-title {
  text-align: center;
  padding-bottom: 30px;
}

.negocio-title h2 {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e1f5de;
  color: #3c9e18;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.negocio-title h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.negocio-title h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.negocio-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  FONT-SIZE: 24px;
  font-family: "Roboto", sans-serif;
}
.negocio-title-left {
  text-align: left;
  padding-bottom: 30px;
}

.negocio-title-left h3 {
  margin: 15px 0 0 0;
  font-size: 28px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.negocio-title-left h3 span {
  color: #3c9e18;
}
@media (min-width: 1024px) {
  .negocio-title p {
    width: 60%;
  }
}

.negocio .icon-box {
  text-align: center;
  border: 1px solid #e1f5de;
  padding: 60px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.negocio .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f6f9fe;
  border-radius: 4px;
  border: 1px solid #f6f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.negocio .icon-box .icon i {
  color: #3c9e18;
  font-size: 40px;
  transition: ease-in-out 0.3s;
}
.accordion-header h3 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
}

.negocio .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
}

.negocio .icon-box h4 a {
  color: #000000;
  transition: ease-in-out 0.3s;
  text-decoration: none;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
}

.negocio .icon-box p {
  line-height: 130%;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  margin-top: 25px;
  text-align: justify;
  color: #555555;
}

.negocio .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(101, 102, 102, 0.3);
}

.negocio .icon-box:hover h4 a,
.negocio .icon-box:hover .icon i {
  color: #0593ea;
  text-decoration: none;
}

.negocio .icon-box:hover .icon {
  border-color: #0593ea;
}

.negocio .icon-box {
  position: relative;
}

.negocio .icon-box .link {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #0593ea;
}

.negocio .icon-box:hover .link {
  display: inline;
}

/*--------------------------------------------------------------
# newsletter
--------------------------------------------------------------*/
.newsletter {
  background: url("../../assets/img_logos_radar/banner_trans.png");
  background-repeat: no-repeat;
  background-color: #f1f6fe;
  background-attachment: fixed;
  background-position: center;
}

.newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.newsletter .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
  align-content: center;
}

.newsletter .btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.newsletter .columna {
  column-count: 2;
  column-gap: 2em;
}
.newsletter .columna .title-color {
  text-shadow: 4px 4px 5px #555555;
  text-align: right;
}
.newsletter .columna .title-color h3 {
  font-size: 90px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #0593ea;
}
.newsletter .title-color h3 span {
  color: #0593ea;
  font-size: 180px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 700px) {
  .newsletter .title-color h3 span {
    font-size: 115px;
  }
}

.newsletter .columna .title-color-2 {
  text-align: left;
  padding: 10px 0px 10px 0px;
}
.newsletter .columna .title-color-2 h3 {
  font-size: 34px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #0593ea;
}
.newsletter .columna .title-color-2 h3 span {
  color: #3c9e18;
  font-size: 34px;
  font-family: "Roboto", sans-serif;
  line-height: 100%;
}
.newsletter .columna .title-color-2 h6 {
  color: #000000;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: left;
  line-height: 130%;
  padding-top: 10px;
  font-style: italic;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #106eea;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/
@media (max-width: 990px) {
  .faq {
    padding: 0;
  }
}

.faq .imgpush {
  background-image: url("../../assets/img/push.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}
.faq .imgsms {
  background-image: url("../../assets/img/sms.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}
.faq .imgemail {
  background-image: url("../../assets/img/email.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
}

.faq .accordion-item {
  border: 0;
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 15px 40px 20px 60px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  color: #555555;
  text-align: left;
  background: #f1f6fe;
  box-shadow: none;
  border-radius: 5px;
}

.faq .accordion-button:not(.collapsed) {
  color: #3c9e18;
  border-bottom: 0;
  box-shadow: none;
}

.faq .question-icon {
  position: absolute;
  top: 14px;
  left: 25px;
  font-size: 20px;
  color: #3c9e18;
}

.faq .accordion-button:after {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #f1f6fe;
}

.faq .accordion-body {
  padding: 0 30px 25px 60px;
  border: 0;
  border-radius: 5px;
  background: #f1f6fe;
  box-shadow: none;
}
.faq .accordion-body p {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  color: #555555;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 4px;
}

.button {
  background-color: #fff;
  border-color: #fff;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 0%;
  padding: 1px 6px;
  border-width: 0px;
  border-style: outset;
  border-color: #fff;
  border-image: initial;
}

@media (max-width: 990px) {
  section {
    padding: 70px 0;
    overflow: hidden;
  }
  .newsletter .columna {
    display: grid;
    justify-content: center !important;
  }
  .newsletter .columna .title-color h3 {
    font-size: 100px;
    text-align: center;
  }
  .newsletter .title-color h3 span {
    font-size: 180px;
    text-align: center;
  }
  .newsletter .columna .title-color-2 h3,
  .newsletter .columna .title-color-2 h3 span {
    font-size: 34px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .newsletter .columna .title-color-2 h6 {
    text-align: center;
    font-size: 24px;
    padding-bottom: 20px;
  }
  .newsletter .btn-get-started {
    font-size: 18px;
  }
}

@media (max-width: 580px) {
  .newsletter .columna .title-color-2 h3,
  .newsletter .columna .title-color-2 h3 span {
    font-size: 28px;
    padding: 0px 20px 20px 20px;
  }
  .newsletter .columna .title-color-2 h6 {
    padding: 0px 20px 20px 20px;
    font-size: 20px;
  }
  .negocio-title h3 {
    margin: 15px 0 0 0;
    font-size: 50px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }
}


/* estaba en banner
  .about {
    padding: 20px 30px;
  }
  .about .icon-box-green h4,
  .about .icon-box h4 {
    font-size: 38px;
  }
  .about .icon-box p,
  .about .icon-box-green p,
  .about .icon-box-purple p {
    font-size: 30px;
  }
  .negocio .content h3 {
    font-weight: 600;
    font-size: 30px;
  }
  .negocio .content p {
    font-size: 28px;
    padding-top: 20px;
  }
  .negocio .content ul h5 {
    font-size: 28px;
  }
  .negocio .content ul p {
    font-size: 28px;
    font-family: "Roboto", sans-serif;
    line-height: 150%;
  }
  .negocio .content ul i {
    font-size: 28px;
  }
  .negocio .content ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
  }*/