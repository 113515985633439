/*--------------------------------------------------------------
# recursos
--------------------------------------------------------------*/
.recursos {
  padding: 0;
  margin-bottom: 60px;
}

.recursos_varios {
  padding: 0;
  margin-bottom: 60px;
}

.recursos .container {
  padding: 80px;
  background: #e1f5de;
  border-radius: 15px;
}

.recursos .content h3 {
  color: #000;
  font-size: 40px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.recursos .content p {
  color: #555555;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 130%;
  padding: 20px 0px 20px 0px;
}

.recursos .content .recursos-btn {
  color: var(--color-white);
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 10px;
  background: rgba(var(--color-primary-dark-rgb), 0.9);
}

.recursos .content .recursos-btn:hover {
  background: var(--color-primary);
}

.recursos .img {
  position: relative;
}

.recursos .img:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #3c9e18;
  border-radius: 15px;
  transform: rotate(12deg);
}

.recursos .img:after {
  content: "";
  position: absolute;
  inset: 0;
  background: #0e639c;
  border-radius: 15px;
  transform: rotate(6deg);
}

.recursos .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}
.recursos .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e18;
  text-decoration: none;
}

.recursos .btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

/*--------------------------------------------------------------
# recursos_varios
--------------------------------------------------------------*/
.recursos_varios {
  padding: 0;
  margin-bottom: 40px;
}


.recursos_varios .content h3 {
  color: #000;
  font-size: 40px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.recursos_varios .content p {
  color: #555555;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  line-height: 150%;
}

.recursos_varios .img {
  position: relative;
}

.recursos_varios .img img {
  position: relative;
  z-index: 3;
  border-radius: 15px;
}

.recursos_varios .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #3c9e19;
  text-decoration: none;
}

.recursos_varios .btn-get-started:hover {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}


.recursos_varios .recurs {
  padding-bottom: 60px;
}

.titulo_recursos .section-title-recursos h3 {
  margin: 15px 0 0 0;
  font-size: 60px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.titulo_recursos .section-title-recursos h3 span {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

.titulo_recursos .section-title-recursos p {
  margin: 15px auto 0 auto;
  font-weight: 600;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.colour {
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  background-clip:text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

@media (max-width: 990px) {
  .section-title h3 {
    font-size: 60px;
  }
  .section-title p {
    font-size: 28px;
  }
  .recursos .container {
    padding: 60px;
  }
  .recursos_varios .container {
    padding: 30px;
  }

  .recursos_varios .content h3 {
    margin-top: 50px;
  }
  .recursos_varios .content p {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .recursos_varios .btn-get-started {
    font-size: 24px;
  }
  .recursos .content p {
    font-size: 28px;
    padding: 20px 0px 20px 0px;
  }
  .recursos .btn-get-started {
    font-size: 24px;
  }
}

@media (max-width: 580px) {
  .titulo_recursos {
    padding: 10px 0px 30px 0px;
  }
  .recursos {
    padding: 0px 20px 0px 20px;
    margin-bottom: 40px;
  }
  .recursos .container {
    padding: 40px;
  }
  .recursos .btn-get-started {
    font-size: 18px;
  }
  .recursos_varios .container {
    padding: 30px;
  }
  .section-title h3 {
    font-size: 50px;
  }
  .section-title p {
    font-size: 24px;
  }
  .recursos_varios .content h3 {
    font-size: 34px;
  }
  .recursos_varios .content p {
    font-size: 28px;
  }
  .recursos_varios .btn-get-started {
    font-size: 18px;
  }
}
