#error404 {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img_logos_radar/banner_error.png") top left;
    background-size: cover;
    position: relative;
    margin: auto;
  }
  
  #error404:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #error404 .container {
    position: relative;
  }
  
  #error404 h1 {
    margin: 0;
    font-size: 100px;
    font-weight: 700;
    line-height: 56px;
    color: #000000;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
  
  #error404 h1 span {
    color: #3C9E18;
    text-align: center;
  }
  
  #error404 h2 {
    color: #555555;
    margin: 30px 0 30px 0;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
  }
  
  #error404 .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #3C9E18;
    
  }
  
  #error404 .btn-get-started:hover {
    background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
    -webkit-text-stroke: 0px black;
    filter: drop-shadow(1px 1px #555555);
  }
  #error404 .d-center{
    text-align: center;
  }
  
  @media (min-width: 1024px) {
    #error404 {
      background-attachment: fixed;
    }
  }