/*body[theme="dark"] {
  --background-body: black;
  --color-text: #fff;
}

body[theme="light"] {
  --background-body: rgb(255, 255, 255);
  --color-text: #000;
}

body {
  transition: 0.5s ease;
  background: var(--background-body) !important;
}

p,
span,
h6
h5,
h4,
h3,
h2,
h1 {
  color: var(--color-text) !important;
}


*/

.info1 {
  height: 30px;
  transition: all 0.5s;
  color: #ffffff;
  background: #0593ea;
  letter-spacing: 0.1em;
  word-spacing: 1px;
}

.info1 .contact-info a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color: #ffffff;
}

.info1 .contact-info a i p {
  padding-left: 5px;
  padding-top: 15px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.info1 .contact-info a i p {
  line-height: 0;
  transition: 0.3s;
}

.info1 .contact-info a i p:hover {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.info1 .social-links a {
  color: rgba(255, 255, 255, 0.7);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

.info1 .social-links a:hover {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 990px) {
  .hide {
    display: none;
  }
  .info1 .contact-info {
    justify-content: center;
    align-items: center;
  }
  .info1 .contact-info a {
    display: flex;
    align-items: center;
  }
  .info1 .contact-info a i {
    margin-right: 2px;
    font-size: 24px;
  }
  .info1 .contact-info a p.hiden {
    padding-top: 13px;
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }
}



@media (max-width: 580px) {
 
  .info1 .contact-info a i {
    margin-right: 2px;
    font-size: 20px;
  }
}