/*body[theme="dark"] {
  --background-body: black;
  --color-text: #fff;
}

body[theme="light"] {
  --background-body: rgb(255, 255, 255);
  --color-text: #000;
}

body {
  transition: 0.5s ease;
  background: var(--background-body) !important;
}

p,
span,
h6 h5,
h4,
h3,
h2,
h1,
li,
a {
  color: var(--color-text) !important;
}


.navbar,
ul,
.dropdown .megamenu 
{
  background: var(--background) !important;
}
*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 80px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.header .fixed-top {
  height: 70px;
}

/*--------------------------------------------------------------
# Logo Menu
--------------------------------------------------------------*/

.header .logo img {
  max-height: 80px;
}

.scrolled-offset {
  margin-top: 70px;
}

.header h2 {
  color: #000000;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/*------------------------------------- Desktop Navigation ---*/

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

ul.closed-mb {
  display: none;
}

.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 18px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
  text-decoration: none;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #0593ea;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #0593ea;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

/*--submenu---------*/
.navbar .megamenu {
  position: static;
}

.navbar .megamenu ul {
  right: 0;
  padding: 10px;
  display: flex;
}

.navbar .megamenu ul li {
  flex: 1;
}

.navbar .megamenu ul li a,
.navbar .megamenu ul li:hover > a {
  color: #143906;
  background: none;
  font-size: 19px;
  font-family: "Roboto", sans-serif;
  padding: 8px 15px 0px 15px;
}

.navbar .megamenu ul li a:hover,
.navbar .megamenu ul li .active,
.navbar .megamenu ul li .active:hover {
  color: #0593ea;
  background: none;
  font-weight: bold;
}

.navbar .megamenu .titulo_submenu_2 p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin: 0;
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 0px 20px 0px;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*---login------- */

.button-login {
  text-align: center;
  padding-bottom: 30px;
}

.button-login h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 20px;
  color: #000000;
  white-space: nowrap;
  transition: 0.2s;
  position: relative;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}

.button-login h2:hover {
  font-weight: 300;
  padding: 6px 10px;
  color: #fff;
  border-radius: 4px;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-text-stroke: 0px black;
  filter: drop-shadow(1px 1px #555555);
}

/*------------------------------------- Mobile Navigation ---*/

/*---Icono---*/

.mobile-nav-toggle {
  display: none;
}
.oculto_mobile {
  display: none;
}

/*---menu---*/

.navbar ul.open-mb {
  display: block !important;
  position: fixed;
  width: 100%;
  top: 110px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #fff;
}
.open-mb {
  max-height: 100vh;
  transition: max-height 0.3s ease-out;
}

.closed-mb {
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.bi-list,
.bi-x {
  transition: transform 0.3s ease-out;
  background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(1px 1px #000);
}

@media (max-width: 999px) {
  .header .logo img {
    max-height: 100px;
  }
  .header {
    height: 100px;
  }

  /*---Icono de abierto---*/
  .mobile-nav-toggle {
    display: block;
    font-size: 40px;
    cursor: pointer;
    line-height: 0;
    transition: 0.1s;
    position: fixed;
    overflow: hidden;
    top: 60px;
    right: 0;
    left: 90%;
    bottom: 0;
    height: 30px;
  }

  /*---Menu oculto---*/
  .oculto_mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    color: #555555;
    margin-top: 15%;
    margin-bottom: auto;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }

  /*---Menu móvil---*/

  .navbar ul.open-mb {
    display: none;
    position: fixed;
    width: 100%;
    top: 125px;
    left: 0;
    padding: 20px;
    background-color: #fff;
    z-index: 99;
    overflow-y: auto;
  }

  .navbar ul.open-mb li a {
    position: relative;
  }

  .navbar ul.open-mb li.servicios a::after {
    content: "\F112";
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    margin-left: 5px;
  }

  .navbar ul.open-mb li.hide-links a::after {
    content: "\F112";
  }

  .navbar ul.open-mb li a .nav-icon {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    line-height: 1;
  }

  .navbar ul.open-mb li ul {
    display: none;
    list-style: none;
    padding-left: 20px;
  }

  .navbar ul.open-mb li ul li a {
    display: block;
  }

  .navbar ul.open-mb li ul.show {
    display: block;
  }

  .navbar ul.open-mb li a h2 {
    color: #000000;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }
  .navbar ul.open-mb li a h2:hover {
    background: linear-gradient(to right, #9c17ff, #0593ea, #203c16);
    -webkit-text-stroke: 0px black;
    filter: drop-shadow(1px 1px #555555);
    padding: 5px 20px;
    border-radius: 4px;
    color: #fff;
  }
  .navbar ul.open-mb li a:before {
    display: none;
  }

  /*---login------- */

  .navbar ul.open-mb li .button-login {
    background: linear-gradient(to right, #9c17ff, #0593ea, #3c9e18);
    -webkit-text-stroke: 0px black;
    filter: drop-shadow(1px 1px #555555);
    padding: 10px;
    border-radius: 4px;
    justify-content: center;
  }

  .navbar ul.open-mb li .button-login h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    color: #fff;
    text-transform: uppercase;
  }

  /*---Estilos del megamenú en móviles---*/

  .navbar .open-mb .dropdown ul {
    position: static;
    opacity: 1;
    visibility: visible;
    background: none;
    box-shadow: none;
    padding: 0;
  }

  .navbar .open-mb .dropdown ul li {
    display: block;
    min-width: auto;
  }

  .navbar .open-mb .dropdown ul a {
    display: block;
    padding: 10px;
    font-weight: 400;
  }

  .navbar .open-mb .dropdown ul a i {
    font-size: 14px;
  }

  .navbar .open-mb .dropdown:hover > ul {
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
  }

  /*
  .navbar .open-mb .dropdown ul.servicios-submenu {
  display: none;
}

.navbar .open-mb .dropdown:hover > ul.servicios-submenu {
  display: block;
}


.navbar .megamenu ul li a i {
  font-size: 24px;
  line-height: 1;
}
.navbar .open-mb .dropdown ul.servicios-submenu li a i {
  transition: transform 0.3s ease-out;
  transform: rotate(180deg);
}
.navbar .open-mb .dropdown:hover > ul.servicios-submenu li a i {
  transform: rotate(0deg);
}
.navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover > a {
    font-size: 24px;
    line-height: 100%;
  }
*/
}

@media (max-width: 580px) {
  .header .logo img {
    max-width: 90%;
  }
  .header {
    height: 92px;
  }
  .mobile-nav-toggle {
    left: 86%;
    top: 55px;
  }
  .navbar ul.open-mb {
    top: 122px;
  }

  .navbar ul.open-mb li a h2 {
    color: #555555;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
  }

  .oculto_mobile {
    text-transform: uppercase;
    font-size: 20px;
  }

  .navbar ul.open-mb li .button-login h2 {
    font-size: 24px;
  }
}
